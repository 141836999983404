import * as PIXI from 'pixi.js'
import './App.css';

const BaseURL = "https://go.henriksdevserver.net"; //"http://localhost:8080";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}



class AppPixi extends PIXI.Application {
  state : {
    slots : number[];
    win : boolean;
  }

  renderstate : {
    slots : number[];
    columnspin : number[];
    replyrow: number[];
    columns : PIXI.Container[];
    textures : PIXI.Texture[];
    sprites : PIXI.Sprite[];
    time : number;
    timeHalt : number;
    replyready : boolean;
  }

  constructor(props) {
    super(props);
    this.state = {
      slots: [1, 2, 3, 
              4, 5, 6, 
              4, 3, 2],
      win: false
    };


    this.renderstate = {
      slots: [1, 2, 3,
              4, 5, 6,
              5, 4, 3,
              5, 5, 5],
      columnspin: [0, 0, 0],
      replyrow: [0, 0, 0],
      columns: [],
      textures: [],
      sprites: [],
      time: 0.0,
      timeHalt: 0.0,
      replyready: false,      // true when reply from rgs is received
    };


    this.initPixi();

    this.spin = this.spin.bind(this);
  }

  fetchSlotData() {
    fetch(BaseURL + "/slots/data", {
//      method: 'GET',
//      headers: {
//          'Content-Type' : 'text/plain'
//      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.state = {slots: result.Slots, win: result.Win};
          this.renderstate.replyready = true;
          console.log(JSON.stringify(result));
        }).catch((error) => { console.log("error= " + error); });
  }

  spin() {
    console.log("spinning");
    this.fetchSlotData();
    // animate spin for 5000 ms
    this.renderstate.replyready = false;
    this.renderstate.timeHalt = this.renderstate.time + 8;
    this.startAnimateSpin();
    this.updateIcons();
  }
 
  animate(delta : number) {
    let dt = this.ticker.deltaMS / 1000.0;
    this.renderstate.time += dt;
    this.animateSpin(dt);
  }


  startAnimateSpin() {
    for (let c = 0; c < 3; c++) {
      this.winbutton.text = "";      
      this.renderstate.columnspin[c] = 0;
      this.renderstate.replyrow[c] = 2;
      this.renderstate.slots[c] = getRandomInt(6) + 1;
    }
  }

  animateSpin(dt : number) {

    for (let c = 0; c < 3; c++ ) {
      // pixels per second
      let speed = 500; // * (1.0 + c * 0.01);


      this.renderstate.columnspin[c] += dt * speed;
      if (this.renderstate.replyrow[c] < -1)
        this.renderstate.columnspin[c] = 0;
      else {
        if (this.renderstate.columnspin[c] > 150) {
          this.renderstate.columnspin[c] -= 150;
          for (let r = 3; r > 0; r--) {
            let idx = r*3 + c;
            this.renderstate.slots[idx] = this.renderstate.slots[idx-3];
          }
          if (this.renderstate.replyready &&
              this.renderstate.time > this.renderstate.timeHalt + c * 0.5) {
            let idx = c + this.renderstate.replyrow[c]*3;
            this.renderstate.slots[c] = this.state.slots[idx]
            this.renderstate.replyrow[c]--;
          }
          else {
            this.renderstate.slots[c] = getRandomInt(6) + 1;
          }
        }
      }
      this.renderstate.columns[c].y = this.renderstate.columnspin[c];

    }

    if (this.doneSpinning())
      this.winbutton.text = this.resultString();
 

    this.updateIcons();
  }

  componentDidMount() {
    console.log("fetch data");
    this.fetchSlotData();
  } 


  doneSpinning() {
    if (this.renderstate.time < this.renderstate.timeHalt)
      return false;
    for (let i = 0; i < 3; i++)
      if (this.renderstate.replyrow[i] !== -1)
        return false;
    return true;
  }


//                    <img src={BaseURL + "slots/files/sloticon-2.png"}></img>

  slotIcon(slotidx : number) {
    return BaseURL + "/slots/files/sloticon-" + this.state.slots[slotidx] + ".png"; 
  }

  resultString() {
    return this.state.win ? "WIN" : "LOSS";
  }


  winbutton : PIXI.Text = new PIXI.Text("",
    {
      fontFamily: "Arial",
      fontSize: 24,
      fill: 0x000000,
      align: "center"
    });

  screencontainer: PIXI.Container = new PIXI.Container()

  initPixi() {  

    console.log("initPixi()");

//    const container = new PIXI.Container();
    this.stage.addChild(this.screencontainer);

    this.screencontainer.x = this.screen.width / 2;
    this.screencontainer.y = this.screen.height / 2;
    this.screencontainer.width = this.screen.width
    this.screencontainer.height = this.screen.height
//    this.screencontainer.containerUpdateTransform();
    
//    this.screencontainer.x = 800/2; // window.innerWidth / 2;
//    this.screencontainer.y = 600/2; // window.innerHeight / 2;
//    this.screencontainer.width = 800; //window.innerWidth
//    this.screencontainer.height = 600; //window.innerHeight
//    this.screencontainer.containerUpdateTransform();

    const boardcontainer = new PIXI.Container();
//    this.boardcontainer = new PIXI.Container();
//    this.stage.addChild(boardcontainer);

    this.screencontainer.addChild(boardcontainer);

    const bordermask = new PIXI.Graphics();
//    this.stage.addChild(bordermask);
    boardcontainer.addChild(bordermask);
    bordermask.beginFill(0x8bc5ff, 0.4);
//    bordermask.drawRect(
//      this.screencontainer.x - 150 * 1.5,this.screencontainer.y - 150 * 1.5,
//      this.screencontainer.x + 150 * 1.5,this.screencontainer.y + 150);
    bordermask.drawRect(
      -150 * 1.5, -150 * 1.5,
       150 * 1.5 * 2,  150 * 1.5 * 2);
    bordermask.endFill();
    boardcontainer.mask = bordermask;


    for (let i = 0; i < 3; i++) {
      let column = new PIXI.Container();
      column.x = (i-1) * 150;
      column.y = 0; //-150;
      this.renderstate.columns.push(column);
      boardcontainer.addChild(column);
    }

    let icons = [
      "assets/textures/sloticon-1.png",
      "assets/textures/sloticon-2.png",
      "assets/textures/sloticon-3.png",
      "assets/textures/sloticon-4.png",
      "assets/textures/sloticon-5.png",
      "assets/textures/sloticon-6.png",
    ];
 
    for (let i=0; i < icons.length; i++)
      this.renderstate.textures.push(PIXI.Texture.from(icons[i]));

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 3; j++) {
        const icon = new PIXI.Sprite(this.renderstate.textures[j]);
        this.renderstate.sprites.push(icon);
        icon.anchor.set(0.5);
        icon.x = 0;
        icon.y = (i - 2) * 150;
        this.renderstate.columns[j].addChild(icon);        
      };
    };

    const spinbutton = new PIXI.Text("Spin", {
      fontFamily: "Arial",
      fontSize: 24,
      fill: 0x000000,
      align: "center"
    });
    spinbutton.y = 250;
    spinbutton.interactive = true; 
    spinbutton.buttonMode = true;
    spinbutton.on("tap", (event)=>{this.spin()});
    spinbutton.on("pointerdown", (event)=>{this.spin()});

    this.screencontainer.addChild(spinbutton);

    this.winbutton.y = -280;
    this.screencontainer.addChild(this.winbutton);


    this.ticker.add((delta) => {this.animate(delta);});
  }

  updateIcons() {
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 3; j++) {
        let idx = i*3 + j;
        this.renderstate.sprites[idx].texture =
          this.renderstate.textures[this.renderstate.slots[idx]-1];
      }
    }
  }
  
  resizeWindow() {
//    console.log("resize window.innerWidth= " + window.innerWidth +
//      " window.innerHeight= " + window.innerHeight +
//      " screen.width= " + this.screen.width +
//      " screem.height= " + this.screen.height);
    this.renderer.resize(window.innerWidth, window.innerHeight);
/*    
    this.screencontainer.x = this.screen.width/2;
    this.screencontainer.y = this.screen.height/2;
    this.screencontainer.width = this.screen.width;
    this.screencontainer.height = this.screen.height;
*/    
    this.screencontainer.x = window.innerWidth/2;
    this.screencontainer.y = window.innerHeight/2;
    this.screencontainer.width = window.innerWidth;
    this.screencontainer.height = window.innerHeight;
  }

}

export default AppPixi;
