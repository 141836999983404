import React from 'react';
import ReactDOM from 'react-dom';
import * as PIXI from 'pixi.js';
import './index.css';
//import App from './App';
import AppPixi from './AppPixi'; 
import reportWebVitals from './reportWebVitals';

/*
	// REACT APP
	ReactDOM.render(
	  <React.StrictMode>
	    <App />
	  </React.StrictMode>,
	  document.getElementById('root')
	);
*/


	// PIXI APP
const app = new AppPixi({ // PIXI.Application({
//	width: window.innerWidth, height: window.innerHeight, backgroundColor: 0xffffff,
//	resizeTo: document.getElementById('section'),
	width: 800, height: 800, backgroundColor: 0xffffff,
	autoResize: true,
	resolution: window.devicePixelRatio }); // window.devicePixelRatio || 1 });

window.addEventListener('resize', resize);


document.body.appendChild(app.view);


function resize() {
//	app.renderer.resize(window.innerWidth, window.innerHeight)
	app.resizeWindow();
}

// const comp = new AppPixi(); // container); // App();

app.ticker.add((delta) => { 
	app.animate(delta);
	app.resizeWindow()
});
 
app.spin();

//resize()

    
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
